<template>
  <Layout>
    <div class="rwt-portfolio-details rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="inner">
              <div class="details-list">
                <!--                                <div class="thumbnail alignwide">
                                                    <img class="radius w-100" :src="portfolio.largeImage" alt="Corporate Image"/>
                                                </div>-->
                <div class="row row--30">
                  <div class="col-lg-6">
                    <img class="radius w-100" :src="item.image" alt="Corporate Image"/>
                  </div>
                  <div class="col-lg-6 mt_md--30 mt_sm--30">
                    <div class="content-left">
                      <h4 class="title">{{ item.title }}</h4>
                      <div class="single-list-wrapper">
                        <div class="single-list">
                          <label>Brand:</label>
                          <span>{{ item.brand }}</span>
                        </div>
                        <div class="single-list">
                          <label>Manufacturer:</label>
                          <span>{{ item.manufacturer }}</span>
                        </div>
                        <div class="single-list">
                          <label>ASIN:</label>
                          <span>{{ item.asin }}</span>
                        </div>
                        <div class="single-list">
                          <label>UPC:</label>
                          <span>{{ item.upc }}</span>
                        </div>
                        <div class="single-list">
                          <label>Form Factor:</label>
                          <span>{{ item.formFactor }}</span>
                        </div>
                        <div v-if="item.color" class="single-list">
                          <label>Color:</label>
                          <span>{{ item.color }}</span>
                        </div>
                        <div class="single-list">
                          <label>Material:</label>
                          <span>{{ item.material }}</span>
                        </div>
                        <div class="single-list">
                          <label>Special Feature:</label>
                          <span>{{ item.specialFeature }}</span>
                        </div>
                        <div class="single-list">
                          <label>Compatible Phone Models:</label>
                          <span>{{ item.compatiblePhoneModels }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt--40 row--30">
                  <h4 class="title">Details: </h4>
                  <ul>
                    <li v-for="line of item.details.split('\n')">{{
                        line
                      }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="portfolio-gallery mt--40">
                <div v-for="url of item.galleries" :key="url" class="gallery mt--30">
                  <div class="thumbnail">
                    <img class="radius w-100" :src="url"
                         alt="Corporate Image"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import PortfolioItemMixin from '../mixins/PortfolioItemMixin'

export default {
  name: 'PortfolioDetailsPage',
  components: {Layout},
  mixins: [PortfolioItemMixin],
  data() {
    return {
      id: this.$route.params.id,
      item: {}
    }
  },
  methods: {
    getItem(itemId) {
      this.item = this.items.find(item => item.id == itemId);
    }
  },
  created() {
    this.getItem(this.id);
  }
}
</script>
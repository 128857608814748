export default {
    data() {
        return {
            portfolioItems: [
                {
                    id: 1,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                    image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                    title: 'App Development',
                    subtitle: 'Branded client',
                    date: '30 May 2022',
                    client: 'Rainbow Themes',
                    category: 'development',
                    awards: ['2020 Design beautiful apps Finale Awards'],
                    excerpt: 'Design beautiful apps.',
                    body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    btn: {
                        buttonText: 'View Projects',
                        link: '/'
                    },
                    largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                    subContent: {
                        title: 'Digital Marketing.',
                        subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent2: {
                        title: 'App Development.',
                        subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent3: {
                        title: 'Solution.',
                        subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    gallery: {
                        imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                    }
                },
                {
                    id: 2,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    title: 'Business Development',
                    subtitle: 'Awesome portfolio',
                    date: '01 March 2022',
                    client: 'Rainbow Themes',
                    category: 'design',
                    awards: ['2020 Design beautiful apps Finale Awards'],
                    excerpt: 'Design beautiful apps.',
                    body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    btn: {
                        buttonText: 'View Projects',
                        link: '/'
                    },
                    largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    subContent: {
                        title: 'Digital Marketing.',
                        subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent2: {
                        title: 'App Development.',
                        subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent3: {
                        title: 'Solution.',
                        subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    gallery: {
                        imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                    }
                },
                {
                    id: 3,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                    image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                    title: 'Photoshop Design',
                    subtitle: 'Awesome portfolio',
                    date: '01 March 2022',
                    client: 'Rainbow Themes',
                    category: 'art',
                    awards: ['2020 Design beautiful apps Finale Awards'],
                    excerpt: 'Design beautiful apps.',
                    body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    btn: {
                        buttonText: 'View Projects',
                        link: '/'
                    },
                    largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                    subContent: {
                        title: 'Digital Marketing.',
                        subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent2: {
                        title: 'App Development.',
                        subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent3: {
                        title: 'Solution.',
                        subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    gallery: {
                        imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                    }
                },
                {
                    id: 4,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                    image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                    title: 'Native Application',
                    subtitle: 'Awesome portfolio',
                    date: '01 March 2022',
                    client: 'Rainbow Themes',
                    category: 'development',
                    awards: ['2020 Design beautiful apps Finale Awards'],
                    excerpt: 'Design beautiful apps.',
                    body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    btn: {
                        buttonText: 'View Projects',
                        link: '/'
                    },
                    largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                    subContent: {
                        title: 'Digital Marketing.',
                        subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent2: {
                        title: 'App Development.',
                        subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent3: {
                        title: 'Solution.',
                        subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    gallery: {
                        imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                    }
                },
                {
                    id: 5,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                    image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                    title: 'Vue.js Development',
                    subtitle: 'Awesome portfolio',
                    date: '01 March 2022',
                    client: 'Rainbow Themes',
                    category: 'design',
                    awards: ['2020 Design beautiful apps Finale Awards'],
                    excerpt: 'Design beautiful apps.',
                    body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    btn: {
                        buttonText: 'View Projects',
                        link: '/'
                    },
                    largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                    subContent: {
                        title: 'Digital Marketing.',
                        subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent2: {
                        title: 'App Development.',
                        subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent3: {
                        title: 'Solution.',
                        subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    gallery: {
                        imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                    }
                },
                {
                    id: 6,
                    portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                    image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                    title: 'App Installment',
                    subtitle: 'Awesome portfolio',
                    date: '01 March 2022',
                    client: 'Rainbow Themes',
                    category: 'design',
                    awards: ['2020 Design beautiful apps Finale Awards'],
                    excerpt: 'Design beautiful apps.',
                    body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    btn: {
                        buttonText: 'View Projects',
                        link: '/'
                    },
                    largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                    subContent: {
                        title: 'Digital Marketing.',
                        subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent2: {
                        title: 'App Development.',
                        subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent3: {
                        title: 'Solution.',
                        subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    gallery: {
                        imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                    }
                }
            ],
            items: [
                {
                    id: 1,
                    image: require(`@/assets/images/item/1.jpg`),
                    title: 'Daydayup Switch Carrying Case Compatible with Nintendo Switch/Switch OLED, with 20 Games Cartridges Protective Hard Shell Travel Carrying Case Pouch for Console & Accessories, Black',
                    details: "High Quality Case - High Quality Durable hard shell Protect your Nintendo Switch / OLED Model Console free from scratches. A Hook Loop Secures the Nintendo Switch inside the case (We have replaced two elastic straps with a Tab,It is a lot easier to put in and take out the Switch with a Tab), make your switch more stable and convenient when you're traveling or not using.\nUnique Zipper Head Design - We use High Quality Nylon Zipper Head, not like the other Nintendo Switch / OLED Model case's Iron Zipper Head will scratch your precious switch（We show this contrast in the 5th picture）. Zipper ensures your small items do not fall out.\nLarge Storage - A large zippered mesh pocket provides secure storage room for some small Nintendo Switch accessories like small Charger, charging cable, slim power bank, earbuds, and 2 extra Joy-Cons, Easier to Close and Easier to Carry. It also build with 20 game card slots, allowing you to carry and change your favorite games anytime & anywhere.\nMultiple Protection - Our Switch Carry case is big inside but small outside. There is absolutely strong enough for plenty of daily protection. Designed to make your new Nintendo Switch / OLED Model Console even more portable & travel friendly\nService - daydayup adhere to the high-quality products and services for the purpose",
                    manufacturer: "Hestia Goods",
                    brand: 'daydayup',
                    formFactor: "Case",
                    asin: "B0711K97BS",
                    upc: "191094162191",
                    material: "Nylon",
                    specialFeature: "SD Games Cartridges & Mesh Pocket",
                    compatiblePhoneModels: "Nintendo Switch & Nintendo Switch OLED",
                    galleries: [
                        require(`@/assets/images/item/1.jpg`),
                        require(`@/assets/images/item/2.jpg`),
                        require(`@/assets/images/item/3.jpg`),
                        require(`@/assets/images/item/4.jpg`),
                        require(`@/assets/images/item/5.jpg`),
                        require(`@/assets/images/item/6.jpg`),
                        require(`@/assets/images/item/7.jpg`),
                        require(`@/assets/images/item/UPC.jpg`),
                    ]
                },
                {
                    id: 2,
                    image: require(`@/assets/images/item2/1.jpg`),
                    title: 'Hestia Goods Switch Case and Tempered Glass Screen Protector Compatible with Nintendo Switch - Deluxe Hard Shell Travel Carrying Case, Pouch Case for Nintendo Switch Console & Accessories, Streak Red',
                    details: "DELUXE NINTENDO SWITCH CASE - High Quality Durable hard shell Protect your Switch Console free from scratches. A HOOK secures the Switch inside the case (We have replaced two elastic straps with a Tab,It is a lot easier to put in and take out the Switch with a Tab), make your switch more stable and convenient when you're traveling or not using.\nUNIQUE ZIPPER HEAD DESIGN - We use High Quality Nylon Zipper Head, not like the other case's Iron Zipper Head will scratch your precious switch（We show this contrast in the 5th picture), Zipper ensures your small items do not fall out\nLARGE STORAGE - A large zippered mesh pocket provides secure storage room for some small switch accessories like charging cable, slim power bank, ear buds, and 2 extra Joy-Cons, Easier to Close and Easier to Carry. It also build with 20 game card slots, allowing you to carry and change your favorite games anytime & anywhere.\nHigh Quality Tempered Screen Protector - High Quality Tempered Glass Switch Screen Protector ,Install easily, easy to connect the Dock.\nService - If you are not satisfied with the purchase, please feel free to contact us immediately and we will try our best to solve your problem, Hestia Goods adhere to the high-quality products and services for the purpose",
                    manufacturer: "Hestia Goods",
                    brand: 'daydayup',
                    formFactor: "Case",
                    asin: "B0711K97BS",
                    upc: "191094162191",
                    material: "Nylon",
                    specialFeature: "20 Games Cartridges & Mesh Pocket",
                    color: "Red",
                    compatiblePhoneModels: "Nintendo Switch & Nintendo Switch OLED",
                    galleries: [
                        require(`@/assets/images/item2/1.jpg`),
                        require(`@/assets/images/item2/2.jpg`),
                        require(`@/assets/images/item2/3.jpg`),
                        require(`@/assets/images/item2/4.jpg`),
                        require(`@/assets/images/item2/5.jpg`),
                        require(`@/assets/images/item2/6.jpg`),
                        require(`@/assets/images/item2/7.jpg`),
                    ]
                },
                {
                    id: 3,
                    image: require(`@/assets/images/item3/1.jpg`),
                    title: 'Deruitu Switch Carrying Case Compatible with Nintendo Switch/Switch OLED - Fit AC Charger Adapter - with 20 Game Cartridges Hard Shell Travel Switch Pouch for Console & Accessories',
                    details: "Fit Nintendo Switch and OLED Model Original Charger AC Adapter Case - Build with 20 game card slots, High Quality Durable hard Shell Protect your Nintendo Switch Console free from scratches\nZippered Mesh Pocket - A large zippered mesh pocket provides secure storage room for some small Nintendo Switch and OLED Model accessories like small Charger, charging cable, slim power bank, earbuds, and 2 extra Joy-Cons. Zipper ensures your small items do not fall out.\nUnique Inner Zipper Design - We use High Quality Nylon Zipper Head, not like the other Nintendo Switch case's Iron Zipper Head will scratch your precious switch（We show this contrast in the 5th picture.\nMultiple Protection - Our Switch Carry case is big inside but small outside. There is absolutely strong enough for plenty of daily protection. Designed to make your new Nintendo Switch Console even more portable & travel friendly\nService - Deruitu adhere to the high-quality products and services for the purpose",
                    manufacturer: "Hestia Goods",
                    brand: 'daydayup',
                    formFactor: "Case",
                    asin: "B0711K97BS",
                    upc: "191094162191",
                    material: "Nylon",
                    specialFeature: "20 Games Cartridges & Mesh Pocket",
                    color: "Black",
                    compatiblePhoneModels: "Nintendo Switch & Nintendo Switch OLED",
                    galleries: [
                        require(`@/assets/images/item3/1.jpg`),
                        require(`@/assets/images/item3/2.jpg`),
                        require(`@/assets/images/item3/3.jpg`),
                        require(`@/assets/images/item3/4.jpg`),
                        require(`@/assets/images/item3/5.jpg`),
                        require(`@/assets/images/item3/6.jpg`),
                        require(`@/assets/images/item3/7.jpg`),
                    ]
                }
            ]
        }
    }
}